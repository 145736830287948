import { confirm, ConfirmationModalProps } from 'components/confirmation-modal';
import toast from 'components/toast';
import { recoverPassword } from 'queries/auth';

type Config = Omit<ConfirmationModalProps, 'isVisible'>;

interface Props {
  email: string;
  title: string | React.ReactNode;
  body: string | React.ReactNode;
}

export const forgottenPasswordModal = ({
  email,
  title,
  body,
}: Props): { destroy: () => void; update: (newConfig: Config) => void } => {
  return confirm({
    title,
    body: <p className="text-sm text-gray-500">{body}</p>,
    okText: 'Réinitialiser',
    cancelText: 'Annuler',
    onSubmit: async () => {
      try {
        await recoverPassword(email);
        toast.success(`Un email vient d’être envoyé à l’adresse ${email}`);
      } catch (error) {
        toast.error(error.message);

        throw error;
      }
    },
  });
};
