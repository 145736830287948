/* eslint-disable max-lines */
import { forgottenPasswordModal } from 'constants/forgotten-password-modal';

import LoginLayout from 'business/login-layout';
import OtpLoginModal from 'business/otp-login';
import LoginConfirm from 'business/two-factor-authentication/login-confirm';
import BrowserTitle from 'components/browser-title';
import Button from 'components/button';
import Icon from 'components/icon';
import Input from 'components/input';
import { MultiFactorResolver } from 'firebase/auth';
import { tryUpdate } from 'helpers/intercom';
import { isMobile } from 'helpers/is-mobile';
import login, { getErrorMessage } from 'helpers/login';
import noop from 'lodash/noop';
import Image from 'next/image';
import { useRouter } from 'next/router';
import clientUser from 'observables/client-user';
import { loginAuditEvent } from 'queries/audit';
import { isEmailKnown as isEmailKnownQuery } from 'queries/auth';
import { getClientUser } from 'queries/client-users';
import { useRef, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { Roles } from 'types/roles';

const UNKNOWN_EMAIL_ERROR =
  'L’email renseigné ne correspond à aucun utilisateur';

const REVOKED_EMAIL_ERROR =
  'Accès au compte révoqué. Si vous pensez qu’il s’agit d’une erreur, contactez un administrateur';

const Login = (): React.ReactNode => {
  const { push } = useRouter();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>();
  const [isUserLogin, setIsUserLogin] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isLoginConfirmModalVisible, setIsLoginConfirmModalVisible] =
    useState(false);
  const [isOtpModalVisible, setIsOtLoginModalVisible] = useState(false);
  const [hasOtpPhone, setHasOtpPhone] = useState<boolean>();

  const verificationIdRef = useRef<string>();
  const resolverRef = useRef<MultiFactorResolver>();
  const emailRef = useRef<string>();

  const { update } = useIntercom();

  const loginClient = async () => {
    try {
      setIsLoading(true);

      const { outcome, twoFactorConfirmation, session } = await login(
        email,
        password,
      );

      if (outcome === 'logged-in') {
        const user = await getClientUser({ email, withCommonRole: true });
        clientUser.setValue(user);

        const { hmac, name } = session;
        tryUpdate(update, { userHash: hmac, email, name });

        setPasswordError('');
        setIsLoading(false);

        await loginAuditEvent({ userType: 'client-user' });

        push('/client');
        return;
      }

      if (outcome === 'two-factor-confirmation') {
        const { verificationId, resolver } = twoFactorConfirmation;
        verificationIdRef.current = verificationId;
        resolverRef.current = resolver;
        emailRef.current = email;

        setIsLoginConfirmModalVisible(true);
      }
    } catch (error) {
      setIsLoading(false);
      setPasswordError(getErrorMessage(error.code));
      throw error;
    }
  };

  const tryUserLogin = async (email: string) => {
    try {
      setIsLoading(true);
      const { isEmailKnown, hasOtpPhone, isRevoked } =
        await isEmailKnownQuery(email);

      if (isRevoked) {
        setIsLoading(false);
        setEmailError(REVOKED_EMAIL_ERROR);
        return;
      }

      if (isEmailKnown) {
        setHasOtpPhone(hasOtpPhone);
        setIsUserLogin(true);
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setEmailError(UNKNOWN_EMAIL_ERROR);
    } catch (error) {
      setIsLoading(false);
      setEmailError(UNKNOWN_EMAIL_ERROR);
      throw error;
    }
  };

  const handleCloseLoginConfirmModal = () => {
    setIsLoginConfirmModalVisible(false);
  };

  const handleSubmitEmail = async () => {
    if (!email) {
      setEmailError('L’email est obligatoire pour continuer');
      return;
    }

    setEmailError('');

    if (email.endsWith('@wearebold.co')) {
      push('/bolder/login');
      return;
    }

    tryUserLogin(email);
  };

  const handleChangeEmail = ({ target: { value } }) => {
    setEmail(value);
    setEmailError('');
  };

  const handleChangePassword = ({ target: { value } }) => {
    setPassword(value);
  };

  const handleClickGoBack = () => {
    setEmailError('');
    setIsUserLogin(false);
  };

  const handleLogin = (evt) => {
    evt.preventDefault();

    if (!password) {
      setPasswordError('Le mot de passe est obligatoire');
      return;
    }
    loginClient();
  };

  const handleForgottenPassword = () => {
    forgottenPasswordModal({
      email,
      title: 'Réinitialisation du mot de passe',
      body: 'Es-tu sûr de vouloir réinitialiser ton mot de passe ? Tu recevras un email avec les instructions pour mettre à jour ton mot de passe.',
    });
  };

  return (
    <BrowserTitle value="Login | Bold">
      <>
        <Image
          src="/mobile-login.svg"
          alt=""
          width={270}
          height={250}
          className="absolute bottom-0 md:hidden"
        />
        <LoginLayout size="md">
          {isUserLogin ? (
            <div className="relative z-10 flex grow flex-col justify-between md:h-auto md:justify-center">
              <div className="relative mb-10 flex items-center justify-center">
                <Button
                  className="absolute left-2 hidden md:visible"
                  onClick={handleClickGoBack}
                  icon="horizontal-arrow"
                  type="outline-icon"
                />
                <Icon type="bold-logo" className="h-4 w-32" size="custom" />
              </div>
              <div>
                <div className="mb-6 flex justify-center">
                  <p className="text-lg font-bold">{'Accède à ton espace :'}</p>
                </div>
                <form id="login">
                  <div className="space-y-2">
                    {/* Tricks Chrome into believing we have two inputs so it can save credentials */}
                    <Input
                      className="hidden"
                      type="email"
                      autoComplete="email"
                      value={email}
                      onChange={noop}
                      fieldId="email"
                      name="email"
                    />
                    <label
                      htmlFor="password"
                      className="font-bold text-text-2 md:hidden"
                    >
                      {'Mot de passe '}
                      <span className="text-text-7">{'*'}</span>
                    </label>
                    <Input
                      type="password"
                      placeholder={isMobile() ? null : 'Mot de passe'}
                      value={password}
                      onChange={handleChangePassword}
                      error={passwordError}
                      fieldId="password"
                      name="password"
                      autoComplete="current-password"
                    />
                  </div>
                  <div className="mb-6 flex justify-between md:mt-3">
                    <Button
                      type="styleless"
                      onClick={handleForgottenPassword}
                      className="p-0 px-0 text-text-1 md:text-text-3"
                    >
                      {'Mot de passe oublié ?'}
                    </Button>
                    <Button
                      htmlType="submit"
                      id="submit-button"
                      isLoading={isLoading}
                      icon="check"
                      className="hidden md:flex"
                      onClick={handleLogin}
                    >
                      {'Se connecter'}
                    </Button>
                  </div>
                  <div className="flex justify-center">
                    <Button
                      type={isMobile() ? 'outline' : 'styleless'}
                      isLoading={isLoading}
                      onClick={(_) => setIsOtLoginModalVisible(true)}
                      className="w-full md:w-auto"
                    >
                      {'Se connecter sans mot de passe'}
                    </Button>
                  </div>
                  {isOtpModalVisible && (
                    <OtpLoginModal
                      email={email}
                      hasOtpPhone={hasOtpPhone}
                      onClose={() => setIsOtLoginModalVisible(false)}
                    />
                  )}
                </form>
              </div>
              <Button
                htmlType="submit"
                id="submit-button"
                isLoading={isLoading}
                icon="check"
                className="block py-5 md:hidden"
                onClick={handleLogin}
              >
                {'Se connecter'}
              </Button>
            </div>
          ) : (
            <div className="relative z-10 flex grow flex-col justify-between md:h-auto md:justify-center">
              <div className="mb-10 flex justify-center">
                <Icon
                  type="bold-logo"
                  className="h-8 w-48 md:h-4 md:w-32"
                  size="custom"
                />
              </div>
              <div>
                <div className="mb-6 flex justify-center">
                  <p className="text-lg font-bold">{'Accède à ton espace :'}</p>
                </div>
                <form id="login">
                  <div className="mb-3 space-y-2">
                    <label
                      htmlFor="email"
                      className="font-bold text-text-2 md:hidden"
                    >
                      {'Email '}
                      <span className="text-text-7">{'*'}</span>
                    </label>
                    <Input
                      type="email"
                      placeholder={isMobile ? null : 'Email'}
                      autoComplete="email"
                      value={email}
                      onChange={handleChangeEmail}
                      error={emailError}
                      fieldId="email"
                      name="email"
                    />
                    {/* Tricks Chrome into believing we have two inputs so it can suggest credentials */}
                    <Input
                      className="hidden"
                      type="password"
                      autoComplete="current-password"
                      value=""
                      onChange={noop}
                      fieldId="password"
                      name="password"
                    />
                  </div>
                </form>
              </div>

              <div className="mb-6 flex justify-end">
                <Button
                  htmlType="submit"
                  id="submit-button"
                  isLoading={isLoading}
                  className="w-full py-5 md:w-auto md:p-2"
                  onClick={handleSubmitEmail}
                >
                  {'Continuer'}
                </Button>
              </div>
            </div>
          )}
          {/* 2fa only applies to client users */}
          {isLoginConfirmModalVisible && (
            <LoginConfirm
              redirection="/client"
              onClose={handleCloseLoginConfirmModal}
              verificationId={verificationIdRef.current}
              resolver={resolverRef.current}
              role={Roles.ClientUser}
              email={emailRef.current}
            />
          )}
        </LoginLayout>
      </>
    </BrowserTitle>
  );
};

export default Login;
