import Head from 'next/head';

interface Props {
  value?: string;
  children: React.ReactNode;
}

const BrowserTitle = ({ value = 'Bold', children }: Props): React.ReactNode => {
  return (
    <>
      <Head>
        <title>{value}</title>
      </Head>
      {children}
    </>
  );
};

export default BrowserTitle;
