import toast from 'components/toast';
import { MultiFactorResolver } from 'firebase/auth';
import { loginWithTwoFactorVerificationCode } from 'helpers/login';
import { useRouter } from 'next/router';
import { loginAuditEvent } from 'queries/audit';
import { Roles } from 'types/roles';

import SmsCodeConfirm from '../sms-code-confirm';

interface Props {
  onClose: () => void;
  onConfirm?: () => void;
  verificationId: string;
  resolver: MultiFactorResolver;
  role: Roles;
  email: string;
  redirection?: string;
}

const LoginConfirm = ({
  onClose,
  onConfirm,
  verificationId,
  resolver,
  role,
  email,
  redirection,
}: Props): React.ReactNode => {
  const { push } = useRouter();

  const handleConfirm = async (verificationCode: string) => {
    try {
      await loginWithTwoFactorVerificationCode(
        verificationId,
        verificationCode,
        resolver,
        role,
        email,
      );

      await loginAuditEvent({ userType: 'client-user' });

      onClose();

      if (redirection) {
        push(redirection);
        return;
      }

      onConfirm?.();
    } catch {
      toast.error(
        'Il y a eu une erreur lors de la confirmation du code envoyé par SMS.',
      );
    }
  };

  return <SmsCodeConfirm onConfirm={handleConfirm} onClose={onClose} />;
};

export default LoginConfirm;
