import cx from 'clsx';
import Parallax from 'parallax-js';
import { useEffect, useRef } from 'react';

interface Props {
  size?: 'sm' | 'md';
  children: React.ReactNode;
}

const LoginLayout = ({ children, size = 'sm' }: Props): React.ReactNode => {
  const scene = useRef();

  useEffect(() => {
    const parallaxInstance = new Parallax(scene.current, {
      relativeInput: true,
      hoverOnly: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);

  return (
    <div className="h-screen w-screen bg-surface-2 md:flex">
      <div
        className={cx(
          'flex h-screen flex-grow items-center justify-center pb-10 md:flex-grow-0 md:pb-0',
        )}
      >
        <div
          className={cx('flex h-full flex-col px-8 py-5 md:h-auto md:p-20', {
            'w-112': size === 'md',
            'w-96': size === 'sm',
          })}
        >
          {children}
        </div>
      </div>
      <div
        className="relative hidden flex-grow overflow-hidden bg-color-5 md:block"
        data-relative-input="true"
        data-limit-x={10}
        data-limit-y={10}
        id="scene"
        ref={scene}
      >
        <img
          src="/login-image.png"
          data-depth="0.5"
          className="h-full w-full object-cover"
        />
        <img
          src="/login-mask.svg"
          data-depth="0"
          className="h-full w-full object-cover"
        />
      </div>
    </div>
  );
};
export default LoginLayout;
