import api from 'helpers/api';
import { UserTypeQuery } from 'v2.api/src/common-business/types/audit';
import { DEFAULT_PAGESIZE } from 'v2.api/src/common-generic/constants';
import { toQueryString } from 'v2.api/src/common-generic/query-string';
import { BolderAuditEventsEntity } from 'v2.api/src/entities/bolder-audit-events.entity';

export const getBolderRelatedAuditEvents = async (bolderId: string) => {
  const { data } = await api<BolderAuditEventsEntity>({
    method: 'get',
    url: `/v2/bolders/${bolderId}/audit?context=bolder-individual-page`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};

export const getOperationEventsAudit = async (
  companyId: string,
  operationEventId: string,
) => {
  const { data } = await api<BolderAuditEventsEntity>({
    method: 'get',
    url: `/v2/companies/${companyId}/operation-events/${operationEventId}/audit`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};

export const getPaginatedAuditEvents = async ({
  page = 0,
  pageSize = DEFAULT_PAGESIZE,
}: {
  page?: number;
  pageSize?: number;
} = {}) => {
  const { data } = await api<BolderAuditEventsEntity>({
    method: 'get',
    url: `/v2/audit${toQueryString({ page, pageSize })}`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};

export const loginAuditEvent = async ({
  userType,
}: {
  userType: UserTypeQuery;
}) => {
  const { data } = await api<void>({
    method: 'get',
    url: `/v2/authentication/notify-login${toQueryString({ userType })}`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};
