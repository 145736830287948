import {
  getAuth,
  getMultiFactorResolver,
  multiFactor,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  User,
} from 'firebase/auth';
import Cookies from 'js-cookie';
import noop from 'lodash/noop';
import { getHmac } from 'queries/auth';
import { Roles } from 'types/roles';
import { ClientUserEntity } from 'v2.api/src/entities/client-user.entity';

import api, { setAuthorizationHeader, setToken } from './api';

type Session = {
  hmac: string;
  name: string;
};

let recaptchaVerifier;

const completeLogin = async (user: User, email: string, role: Roles) => {
  await setupAccessToken(user);
  setAuthorizationHeader();

  const { hmac, name } = await getHmac();

  Cookies.set('hmac', hmac, { expires: 365 });
  Cookies.set('role', role, { expires: 365 });
  Cookies.set('user_email', email, { expires: 365 });

  return { hmac, name };
};

const login = async (
  email: string,
  password: string,
  customToken?: string,
): Promise<{
  outcome: 'logged-in' | 'two-factor-confirmation';
  session?: Session;
  twoFactorConfirmation?: {
    verificationId: string;
    resolver: MultiFactorResolver;
  };
}> => {
  const auth = getAuth();

  try {
    const { user } = customToken
      ? await signInWithCustomToken(auth, customToken)
      : await signInWithEmailAndPassword(auth, email, password);
    let role: Roles;

    if (email.split('@')[1] !== 'wearebold.co') {
      role = Roles.ClientUser;
    } else {
      const [emailRole] = user.uid.split('+');

      switch (emailRole) {
        case 'bolder':
          role = Roles.Bolder;
          break;
        default:
          role = Roles.Admin;
          break;
      }
    }

    const { hmac, name } = await completeLogin(user, email, role);

    return { outcome: 'logged-in', session: { hmac, name } };
  } catch (error) {
    if (error.code !== 'auth/multi-factor-auth-required') throw error;

    const resolver = getMultiFactorResolver(auth, error);
    const multiFactorHints = resolver.hints;

    // Ask user which second factor to use.
    if (multiFactorHints[0].factorId !== PhoneMultiFactorGenerator.FACTOR_ID) {
      throw error;
    }

    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session,
    };

    if (!recaptchaVerifier) {
      recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
        callback: noop,
      });
    }

    const phoneAuthProvider = new PhoneAuthProvider(auth);

    // Send SMS verification code
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier,
    );

    return {
      outcome: 'two-factor-confirmation',
      twoFactorConfirmation: {
        verificationId,
        resolver,
      },
    };
  }
};

export const loginWithTwoFactorVerificationCode = async (
  verificationId: string,
  verificationCode: string,
  resolver: MultiFactorResolver,
  role: Roles,
  email: string,
): Promise<Session> => {
  // Ask user for the SMS verification code.
  const credentials = PhoneAuthProvider.credential(
    verificationId,
    verificationCode,
  );
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credentials);

  // Complete sign-in.
  const userCredentials = await resolver.resolveSignIn(multiFactorAssertion);

  return completeLogin(userCredentials.user, email, role);
};

export const setupAccessToken = async (user: User): Promise<void> => {
  const { token: accessToken } = await user.getIdTokenResult(true);

  localStorage.setItem('refresh_token', user.refreshToken);

  setToken(accessToken);
};

export default login;

export const enrollTwoFactorAuthentication = async (
  phoneNumber: string,
): Promise<string> => {
  const auth = getAuth();
  const user = auth.currentUser;
  const multiFactorInstance = multiFactor(user);

  const phoneInfoOptions = {
    phoneNumber,
    session: await multiFactorInstance.getSession(),
  };

  const phoneAuthProvider = new PhoneAuthProvider(auth);

  if (!recaptchaVerifier) {
    recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
      callback: noop,
    });
  }

  return phoneAuthProvider.verifyPhoneNumber(
    phoneInfoOptions,
    recaptchaVerifier,
  );
};

export const updateTwoFactorAuthenticationEnrollment = async (
  clientUserId: string,
  body: {
    password: string;
    hasEnrolledToTwoFactorAuthentication: boolean;
    phoneNumber: string;
  },
) => {
  const { data } = await api<ClientUserEntity>({
    method: 'put',
    url: `/v2/client-users/${clientUserId}/two-factor-authentication-enrollment`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    body,
    isAuthenticated: false,
  });

  return data;
};

export const getErrorMessage = (firebaseErrorCode: string) => {
  switch (firebaseErrorCode) {
    case 'auth/invalid-email':
      return 'E-mail invalide';
    case 'auth/user-disabled':
      return 'Utilisateur désactivé';
    case 'auth/wrong-password':
      return 'Le mot de passe est incorrect';
    case 'auth/too-many-requests':
      return 'Ton compte vient d’être bloqué pour des raisons de sécurité, re-tente dans une dizaine de minutes.';
  }
};
