import ConfirmationModal from 'components/confirmation-modal';
import Form from 'components/form';
import { getApiObjectFromFields } from 'helpers/form';
import { useState } from 'react';
import { Error, Fields } from 'v2.api/src/common-generic/types/form';

interface Props {
  onConfirm: (verificationCode: string) => Promise<void>;
  onClose: () => void;
}

const confirmCodeForm: Fields<React.ReactNode> = [
  {
    type: 'input',
    id: 'verificationCode',
    apiMappingField: 'verificationCode',
    label: 'Code reçu par SMS :',
    fieldProps: {
      inputStyle: 'secondary',
    },
    value: '',
  },
];

const SmsCodeConfirm = ({ onConfirm, onClose }: Props): React.ReactNode => {
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [confirmCodeFields, setConfirmCodeFields] =
    useState<Fields<React.ReactNode>>(confirmCodeForm);
  const [errors, setErrors] = useState<Array<Error>>();

  const handleConfirm = async () => {
    const { verificationCode } = getApiObjectFromFields<{
      verificationCode?: string;
    }>(confirmCodeFields);

    await onConfirm(verificationCode);
  };

  return (
    <ConfirmationModal
      isVisible
      okTextType="classic"
      iconType="warning"
      okText="Confirmer"
      cancelText="Annuler"
      onSubmit={handleConfirm}
      onCancel={onClose}
      isOkButtonDisabled={!isFormComplete}
      title="Confirme le code reçu par SMS"
      body={
        <Form
          className="space-y-2"
          fields={confirmCodeFields}
          onChange={setConfirmCodeFields}
          onFormComplete={setIsFormComplete}
          errors={errors}
          onChangeErrors={setErrors}
        />
      }
    />
  );
};

export default SmsCodeConfirm;
