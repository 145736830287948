import Button from 'components/button';
import Modal from 'components/modal';
import toast from 'components/toast';
import Tooltip from 'components/tooltip';
import { signInWithCustomToken, getAuth } from 'firebase/auth';
import { setAuthorizationHeader } from 'helpers/api';
import { setupAccessToken } from 'helpers/login';
import Cookies from 'js-cookie';
import isNaN from 'lodash/isNaN';
import { useRouter } from 'next/router';
import {
  sendOtp as sendOtpQuery,
  verifyOtp as verifyOtpQuery,
  verifyRecaptcha,
} from 'queries/auth';
import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import OtpInput from 'react-otp-input';
import { Roles } from 'types/roles';

interface Props {
  email: string;
  hasOtpPhone: boolean;
  onClose: () => void;
}

const OtpLoginModal = ({ email, hasOtpPhone, onClose }: Props): JSX.Element => {
  const [shouldRenderOtpInputs, setShouldRenderOtpInputs] = useState(false);
  const [otp, setOtp] = useState<string>();
  const [otpType, setOtpType] = useState<'sms' | 'email'>();
  const [isOtpLoading, setIsOtpLoading] = useState(false);

  const { push } = useRouter();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const verifyOtp = async (otpValue: string) => {
    try {
      setIsOtpLoading(true);

      if (isNaN(otpValue)) {
        return toast.error('Mauvais code de vérification');
      }

      const customToken = await verifyOtpQuery(email, Number(otpValue));

      const { user } = await signInWithCustomToken(getAuth(), customToken);

      Cookies.set('role', Roles.ClientUser, { expires: 365 });
      Cookies.set('user_email', email, { expires: 365 });

      await setupAccessToken(user);

      setAuthorizationHeader();

      push('/');
    } catch ({ message }) {
      toast.error(message);
    } finally {
      setIsOtpLoading(false);
    }
  };

  const sendOtp = async (type: 'sms' | 'email') => {
    try {
      const gRecaptchaToken = await executeRecaptcha('otp_login');

      const { success } = await verifyRecaptcha(gRecaptchaToken);

      if (!success) return;

      setIsOtpLoading(true);

      await sendOtpQuery(email, type);

      toast.success(`Un ${type} a bien été envoyé`);
      setShouldRenderOtpInputs(true);
    } catch (error) {
      toast.error(
        'Une erreur est survenue lors de l’envoi du code de vérification',
      );

      throw error;
    } finally {
      setIsOtpLoading(false);
    }
  };

  const renderSmsOtpButton = () => {
    const smsOtpButton = (
      <Button
        type="outline"
        icon="phone"
        isDisabled={!hasOtpPhone}
        isLoading={isOtpLoading}
        onClick={(_) => {
          setOtpType('sms');
          sendOtp('sms');
        }}
      >
        {'SMS'}
      </Button>
    );

    return hasOtpPhone ? (
      smsOtpButton
    ) : (
      <Tooltip label="Aucun numéro renseigné">{smsOtpButton}</Tooltip>
    );
  };

  const renderFooter = () =>
    shouldRenderOtpInputs ? (
      <div className="flex justify-between bg-surface-1 py-2 sm:px-4">
        <Button
          type="outline"
          isLoading={isOtpLoading}
          onClick={(_) => onClose()}
        >
          {'Annuler'}
        </Button>
        <div className="flex">
          <Button
            type="styleless"
            isLoading={isOtpLoading}
            onClick={(_) => sendOtp(otpType)}
          >
            {'Renvoyer un code ?'}
          </Button>
          <Button
            htmlType="submit"
            icon="check"
            isLoading={isOtpLoading}
            onClick={(_) => verifyOtp(otp)}
          >
            {'Valider'}
          </Button>
        </div>
      </div>
    ) : (
      <div className="flex justify-evenly bg-surface-1 py-2">
        {renderSmsOtpButton()}
        <Button
          type="outline"
          icon="letter"
          isLoading={isOtpLoading}
          onClick={(_) => {
            setOtpType('email');
            sendOtp('email');
          }}
        >
          {'E-mail'}
        </Button>
      </div>
    );

  return (
    <Modal
      onCancel={onClose}
      isVisible
      contentClassName="!w-96"
      title={
        shouldRenderOtpInputs
          ? 'Vérifier le code de connexion'
          : 'Se connecter sans mot de passe'
      }
      body={
        shouldRenderOtpInputs ? (
          <div className="flex justify-center">
            <OtpInput
              containerStyle="space-x-1.5"
              inputStyle="!w-12 h-8 my-4 text-xl rounded border border border-border-4"
              inputType="tel"
              shouldAutoFocus
              renderInput={(props) => <input {...props} />}
              value={otp}
              onChange={async (otpValue) => {
                if (otpValue.length === 6) {
                  setOtp(otpValue);

                  await verifyOtp(otpValue);

                  return;
                }
                setOtp(otpValue);
              }}
              numInputs={6}
              renderSeparator={<span> </span>}
            />
          </div>
        ) : (
          <p className="text-center font-medium">
            {'Recevoir un code à usage unique par :'}
          </p>
        )
      }
      renderFooter={renderFooter}
    />
  );
};

export default OtpLoginModal;
